import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { USER_SIGN_UP_TYPE } from "src/utils/constants";
import LoginPage from "./Login/LoginPage";
import RegistrationPage from "./Registration/RegistrationPage";
import VerifyEmailPage from "./Registration/VerifyEmailPage";
import VerifyPhonePage from "./Registration/VerifyPhonePage";
import ForgotPin from "./ForgotPin/ForgotPin";
import Logout from "./Logout/Logout";
import {
  PAGE_ROUTE_FORGOT_PIN,
  PAGE_ROUTE_LOGIN,
  PAGE_ROUTE_LOGOUT,
  PAGE_ROUTE_REGISTRATION,
  PAGE_ROUTE_SELECT_COUNTRY,
  PAGE_ROUTE_VERIFY_EMAIL,
  PAGE_ROUTE_VERIFY_PHONE,
  PAGE_ROUTE_VERIFY_REGISTRATION,
} from "./pageRoutes";
import {SelectCountryPage} from "src/components/Registration/SelectCountryComponent";
interface Props {
  overrideHash?: string;
}

const PageRouter = (props: Props) => {
  if(props.overrideHash){
    window.location.hash = props.overrideHash
  }
  
  return (
    <div id="pageRouter">
      <Switch>
        <Route
          exact
          path={`/${PAGE_ROUTE_LOGIN}`}
          component={() => <LoginPage />}
        />
        <Route
            exact
            path={`/${PAGE_ROUTE_SELECT_COUNTRY}`}
            component={() => <SelectCountryPage />}
        />
        <Route
          exact
          path={`/${PAGE_ROUTE_REGISTRATION}`}
          component={() => <RegistrationPage signUpType={USER_SIGN_UP_TYPE.CREATE}/>}
        />
        <Route
          exact
          path={`/${PAGE_ROUTE_VERIFY_REGISTRATION}`}
          component={() => <RegistrationPage signUpType={USER_SIGN_UP_TYPE.VERIFY}/>}
        />
        <Route
          exact
          path={`/${PAGE_ROUTE_VERIFY_EMAIL}`}
          component={() => <VerifyEmailPage />}
        />
        <Route
          exact
          path={`/${PAGE_ROUTE_VERIFY_PHONE}`}
          component={() => <VerifyPhonePage />}
        />
        <Route
          exact
          path={`/${PAGE_ROUTE_FORGOT_PIN}`}
          component={() => <ForgotPin />}
        />
        <Route
          exact
          path={`/${PAGE_ROUTE_LOGOUT}`}
          component={() => <Logout />}
        />
        {/* <Route path="/404" component={() => <NotFound />} /> */}
        {!props.overrideHash && <Redirect to={`/${PAGE_ROUTE_LOGIN}`}/>}
      </Switch>
    </div>
  );
};

export default PageRouter;
